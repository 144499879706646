<template>
  <div class="bgc">
    <el-button @click="iscn" class="cn">简体中文/English</el-button>
    <div class="deng">
      <el-form
        ref="loginFormRef"
        :model="loginForm"
        label-width="80px"
        :rules="loginRules"
      >
        <el-form-item prop="username" v-if="Cn">
          <span slot="label" class="zi">用户名</span>
          <el-input v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="username" v-else="!Cn">
          <span slot="label" class="zi">username</span>
          <el-input v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="password" v-if="Cn">
          <span slot="label" class="zi">密码</span>
          <el-input v-model="loginForm.password"></el-input>
        </el-form-item>
        <el-form-item prop="password" v-else="!Cn">
          <span slot="label" class="zi">password</span>
          <el-input v-model="loginForm.password"></el-input>
        </el-form-item>
        <el-form-item v-if="Cn">
          <el-button @click="denglu" class="fl">登录</el-button>
          <el-button @click="zhuce" class="fr">注册</el-button>
        </el-form-item>
        <el-form-item v-else="!Cn">
          <el-button @click="denglu" class="fl">Log On</el-button>
          <el-button @click="zhuce" class="fr">Registered account</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog title="登录失败" :visible.sync="loginDialogVisible" width="30%">
      <span>{{ this.loginErr }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="loginDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="loginDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Cn: true,
      loginForm: {
        username: "",
        password: "",
      },
      loginFormRef: "",
      loginDialogVisible: false,
      loginErr: "",
      loginRules: {
        username: [
          {
            required: true,
            message: "请输入用户名/Please into your user name",
            trigger: "blur",
          },
          {
            min: 3,
            max: 15,
            message:
              "用户名长度在3到15位/The user name is between 3 and 15 digits in length",
            trigger: "blur",
          },
          {
            validator: function (rule, value, callback) {
              //  校验英文的正则
              if (/[a-zA-z]$/.test(value) == false) {
                callback(new Error("请输入英文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码/Please into your password",
            trigger: "blur",
          },
          {
            min: 3,
            max: 15,
            message:
              "密码长度在3到15位/Password length is between 3 and 15 digits",
            trigger: "blur",
          },
          {
            validator: function (rule, value, callback) {
              //  校验英文的正则
              if (/[a-zA-z][0-9]*$/.test(value) == false) {
                callback(
                  new Error(
                    "请输入英文和数字组成的密码/Please enter a password in English and numbers"
                  )
                );
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    iscn() {
      this.Cn = !this.Cn;
      console.log(this.Cn);
    },
    async getname() {
      const { data: res } = await this.$http.get(
        "/api/gamename?username=" + this.loginForm.username
      );
      window.sessionStorage.gamename = res.gamename;
    },
    denglu() {
      this.$refs.loginFormRef.validate(async (validate) => {
        if (validate) {
          const { data: res } = await this.$http.post(
            "/api/login?username=" +
              this.loginForm.username +
              "&password=" +
              this.loginForm.password
          );
          //登录认证
          if (res.loginCode == 1) {
            this.getname();
            //管理员认证
            if (res.user_identity == 1) {
              this.$router.push("/paimoon");
            } else {
              this.$router.push("/userpaimoon");
            }
          } else {
            this.loginErr = res.loginMessage;
            this.loginDialogVisible = true;
          }
        } else {
          alert("请填写账户和密码/Please fill in the account and password");
        }
      });
    },
    zhuce() {
      this.$router.push("/zhuce");
    },
  },
};
</script>
<style lang="less" scoped>
.bgc {
  height: 100%;
  background-image: url(../assets/image/bg.gif) !important;
  .cn {
    position: absolute;
    left: 90%;
    top: 0;
  }
  .deng {
    width: 400px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .zi {
      color: white;
      font-size: 15px;
    }
  }
}
</style>