<template>
  <div class="list">
    <el-card class="moon-card">
      <!-- <el-table
        :data="moonTable"
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <el-table-column prop="moon_name" label="月矿堡名称" width="400">
        </el-table-column>
        <el-table-column prop="moon_type" label="月矿类型" sortable width="200">
        </el-table-column>
      </el-table> -->
      <el-table :data="uvList" style="width: 100%" height="600px">
        <el-table-column label="月矿堡名称" prop="moon_name" v-if="this.$store.state.Cn">
        </el-table-column>
        <el-table-column label="Name of Moon Mining Castle" prop="moon_name" v-else="!this.$store.state.Cn">
        </el-table-column>
        <el-table-column label="月矿类型" prop="moon_type" v-if="this.$store.state.Cn">
        </el-table-column>
        <el-table-column label="Mineral type" prop="moon_type" v-else="!this.$store.state.Cn">
        </el-table-column>
        <el-table-column label="租聘者" prop="moon_hirer" v-if="this.$store.state.Cn">
        </el-table-column>
        <el-table-column label="Hirer of Moon Mining Castle" prop="moon_hirer" v-else="!this.$store.state.Cn">
        </el-table-column>
        <el-table-column align="right" v-if="this.$store.state.Cn">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" size="mini" placeholder="输入关键字搜索(不支持大写，请输入小写字母)" />
          </template>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
          </template>
        </el-table-column>
        <el-table-column align="right" v-else="!this.$store.state.Cn">
          <template slot="header" slot-scope="scope">
            <el-input v-model="search" size="mini"
              placeholder="Enter keyword search (uppercase is not supported, please enter lowercase letters)" />
          </template>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">Edit</el-button>
            <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[5, 10]" layout="total, sizes, prev, pager, next ,jumper" :total="total">
      </el-pagination>

      <!-- 修改月矿堡对话框 -->
      <el-dialog title="修改月矿堡" :visible.sync="editDialogVisible" width="40%" v-if="this.$store.state.Cn">
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="130px">
          <el-form-item label="修改后月矿堡名称" prop="moonname">
            <el-input v-model="editForm.moonname"></el-input>
          </el-form-item>
          <el-form-item label="矿物类型" prop="addtype">
            <el-select v-model="editForm.moontype" placeholder="请选择月矿矿物类型">
              <el-option label="64" value="64"></el-option>
              <el-option label="32" value="32"></el-option>
              <el-option label="16" value="8"></el-option>
              <el-option label="8" value="8"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="租赁者" prop="moonhirer">
            <el-input v-model="editForm.moonhirer"></el-input>
          </el-form-item>
          <el-form-item label="修改前月矿堡名称" prop="moon_name">
            <el-input v-model="editForm.moon_name" disabled></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="editmoon">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 英文 -->
      <el-dialog title="Edit Moon Mining Castle" :visible.sync="editDialogVisible" width="60%"
        v-else="!this.$store.state.Cn">
        <el-form :model="editForm" :rules="editFormRules" ref="editFormRef" label-width="450px">
          <el-form-item label="Name of the modified Moon Mine Castle" prop="moonname">
            <el-input v-model="editForm.moonname"></el-input>
          </el-form-item>
          <el-form-item label="Mineral type" prop="addtype">
            <el-select v-model="editForm.moontype" placeholder="Please select the type of moon mineral">
              <el-option label="64" value="64"></el-option>
              <el-option label="32" value="32"></el-option>
              <el-option label="16" value="8"></el-option>
              <el-option label="8" value="8"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Hirer of the modified Moon Mine Castle" prop="moonhirer">
            <el-input v-model="editForm.moonhirer"></el-input>
          </el-form-item>
          <el-form-item label="Before modification Name of the modified Moon Mine Castle" prop="moon_name">
            <el-input v-model="editForm.moon_name" disabled></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="editDialogVisible = false">Cancel</el-button>
          <el-button type="primary" @click="editmoon">Define</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: "listMoon",

  data() {
    return {
      allUvlist: [],
      //搜索内容
      search: "",
      //当前页数
      currentPage: 1,
      // 总条数
      total: 0,

      // 显示一页多少条数据
      pagesize: 10,
      //修改月矿堡对话框
      editDialogVisible: false,
      //修改月矿堡表单
      editForm: {
        moonname: "",
        moontype: "",
        moonhirer: "",
        moon_name: "",
      },
      editFormRef: "",
      //修改月矿堡表单规则
      editFormRules: {},
      deleteForm: {},
    };
  },
  methods: {
    async getlist() {
      const { data: res } = await this.$http.get("/api/moonpai");
      console.log(res);
      this.allUvlist = res;
      this.total = res.length;

    },
    //弹开修改月矿堡对话框
    handleEdit(index, row) {
      this.editForm.moonname = row.moon_name;
      this.editForm.moontype = row.moon_type;
      this.editForm.moonhirer = row.moon_hirer;
      this.editForm.moon_name = row.moon_name;
      this.editDialogVisible = true;
    },
    //修改月矿堡
    editmoon() {
      this.$http({
        method: "put",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url: "/api/moon/edit",
        // 只有params是可以传递参数的
        data: this.editForm,
      })
        .then((res) => {
          this.editDialogVisible = false;
          this.getlist();
          alert(res.data.edit);
        })
        .catch((err) => {
          console.log("失败");
        });
    },
    //删除月矿堡
    handleDelete(index, row) {
      this.deleteForm = row;
      this.$http({
        method: "delete",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        url: "/api/moon/delete",
        data: this.deleteForm,
      })
        .then((res) => {
          this.getlist();
          console.log("成功");
        })
        .catch((err) => {
          console.log("失败");
        });
    },
    //监听 pagesize 改变的事件
    handleSizeChange(newsize) {
      //这里conso 选中第几页 最新的值
      console.log(newsize);
      //最新的条数（newsize）赋值给 动态的 pagesie
      this.pagesize = newsize;
      //获取到最新一页显示的数据  重新发送axios请求 这里是封装好的请求方法
      // this.userlistinfo();
    },

    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      console.log(newPage);
      //把最新的页码（newPage）赋值给 动态的 pagenum
      // this.pagenum = newPage;
      this.currentPage = newPage;
      //获取到最新显示的页码值  重新发送axios请求 这里是封装好的请求方法
      // this.userlistinfo();
    },
  },
  mounted() {
    this.getlist();
  },
  computed: {
    uvList() {
      // if (typeof this.allUvList === "undefined") {
      //   console.log(this);
      //   return;
      // } else {
      let list = this.allUvlist.filter(
        (item) =>
          item["moon_name"].toString().toLowerCase().indexOf(this.search) > -1
      );
      let table_data = list.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
      return table_data;
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  .moon-card {
    width: 80%;
    height: 800px;
    position: absolute;
    left: 301px;
    top: 80px;
  }
}
</style>