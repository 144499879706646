<template>
  <dir>
    <el-card class="top-card">
      <div class="fl" v-if="this.$store.state.Cn">
        {{
          "&emsp;月矿堡名称：" +
          moonpaiTable.moon_name +
          "&emsp;&emsp;月矿类型：" +
          moonpaiTable.moon_type
        }}
      </div>
      <div class="fl" v-else="!this.$store.state.Cn">
        {{
          "&emsp;Name of Moon Mining Castle：" +
          moonpaiTable.moon_name +
          "&emsp;&emsp;Mineral type：" +
          moonpaiTable.moon_type
        }}
      </div>
      <div class="fr" v-if="this.$store.state.Cn">
        <!-- <div style="width: 100%; display: inline-block; " class="cout">
          <el-card class="cout-card"> <el-statistic :value="deadline2" time-indices title="月矿大拍卖" format="DD天HH小时mm分钟ss秒">
              <template slot="suffix">
                拍卖结束
              </template>
            </el-statistic></el-card>
        </div> -->
        <div style="width: 100%; display: inline-block; " class="bookcard">
          <span v-if="this.paimaibtn == '1'">拍卖已开启</span>
          <span v-else="this.paimaibtn == '0'">拍卖已关闭</span>
        </div>
        <div class="btn">
          <el-button @click="switchmoon">切换月矿堡</el-button>
          <el-button @click="paimoon" :disabled="this.count">拍卖月矿堡</el-button>
          <el-button @click="cn">English</el-button>
        </div>
      </div>
      <div class="fr" v-else="!this.$store.state.Cn">
        <!-- <div style="width: 100%; display: inline-block; " class="cout">
          <el-card class="cout-card"> <el-statistic :value="deadline2" time-indices title="Moon Auction"
              format="DDdayHHhourmmminutesssecond">
              <template slot="suffix">
                Auction End
              </template>
            </el-statistic></el-card>
        </div> -->
        <div style="width: 100%; display: inline-block; " class="bookcard">
          <span v-if="this.paimaibtn == '1'">Open this auction</span>
          <span v-else="this.paimaibtn == '0'">Close this auction</span>
        </div>
        <div class="btn">
          <el-button @click="switchmoon">Switch</el-button>
          <el-button @click="paimoon" :disabled="this.count">auction</el-button>
          <el-button @click="cn">中文简体</el-button>
        </div>
      </div>
    </el-card>

    <!-- 切换月矿堡对话框 -->
    <el-dialog title="月矿堡列表" :visible.sync="topDialogVisible" width="60%" v-if="this.$store.state.Cn">
      <el-card>
        <el-table :data="topList" style="width: 100%" height="600px">
          <el-table-column label="月矿堡名称" prop="moon_name">
          </el-table-column>
          <el-table-column label="月矿类型" prop="moon_type"> </el-table-column>
          <el-table-column label="租聘者" prop="moon_hirer"> </el-table-column>
          <el-table-column align="right">
            <template slot="header" slot-scope="scope">
              <el-input v-model="search" size="mini" placeholder="输入关键字搜索(不支持大写，请输入小写字母)" />
            </template>
            <template slot-scope="scope">
              <el-button size="mini" @click="handleswitch(scope.$index, scope.row)">切换</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[5, 10]" layout="total, sizes, prev, pager, next ,jumper" :total="total">
        </el-pagination>
      </el-card>
    </el-dialog>
    <!-- 切换月矿堡对话框英文 -->
    <el-dialog title="月矿堡列表" :visible.sync="topDialogVisible" width="60%" v-else="!this.$store.state.Cn">
      <el-card>
        <el-table :data="topList" style="width: 100%" height="600px">
          <el-table-column label="Name of Moon Mining Castle" prop="moon_name" width="250">
          </el-table-column>
          <el-table-column label="Mineral type" prop="moon_type" width="180">
          </el-table-column>
          <el-table-column label="Hirer of Moon Mining Castle" prop="moon_hirer">
          </el-table-column>
          <el-table-column align="right" width="180">
            <template slot="header" slot-scope="scope">
              <el-input v-model="search" size="mini"
                placeholder="Enter keyword search (uppercase is not supported, please enter lowercase letters)" />
            </template>
            <template slot-scope="scope">
              <el-button size="mini" @click="handleswitch(scope.$index, scope.row)">Switch</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
          :page-sizes="[5, 10]" layout="total, sizes, prev, pager, next ,jumper" :total="total">
        </el-pagination>
      </el-card>
    </el-dialog>
    <!-- 拍卖月矿堡对话框 -->
    <el-dialog title="提交拍卖" :visible.sync="paiDialogVisible" width="60%" v-if="this.$store.state.Cn">
      <el-form :model="paiForm" :rules="paiFormRules" ref="paiFormRef" label-width="100px">
        <el-form-item label="月矿堡名称" prop="moonname">
          <el-input v-model="paiForm.moonname" disabled></el-input>
        </el-form-item>
        <el-form-item label="月矿类型" prop="moontype">
          <el-input v-model="paiForm.moontype" disabled></el-input>
        </el-form-item>
        <el-form-item label="游戏名称" prop="gamename">
          <el-input v-model="paiForm.gamename" disabled></el-input>
        </el-form-item>
        <el-form-item label="价格(Misk)" prop="money">
          <el-input v-model="paiForm.money"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paiDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="paimai" :disabled="this.count">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 拍卖月矿堡对话框英文 -->
    <el-dialog title="Submit for auction" :visible.sync="paiDialogVisible" width="60%" v-else="!this.$store.state.Cn">
      <el-form :model="paiForm" :rules="paiFormRules" ref="paiFormRef" label-width="250px">
        <el-form-item label="Name of Moon Mining Castle" prop="moonname">
          <el-input v-model="paiForm.moonname" disabled></el-input>
        </el-form-item>
        <el-form-item label="Mineral type" prop="moontype">
          <el-input v-model="paiForm.moontype" disabled></el-input>
        </el-form-item>
        <el-form-item label="Gamename" prop="gamename">
          <el-input v-model="paiForm.gamename" disabled></el-input>
        </el-form-item>
        <el-form-item label="Money(Misk)" prop="money">
          <el-input v-model="paiForm.money"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="paiDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="paimai" :disabled="this.count">Determine</el-button>
      </span>
    </el-dialog>
  </dir>
</template>

<script>
import moment from 'moment';
export default {
  name: "listTop",
  data() {
    return {
      nowtime: '',
      count: false,
      deadline: '',
      deadline2: '',
      //活动拍卖数据的
      moonpaiTable: {
        moon_name: "请选择你所要拍卖的月矿",
        moon_type: "请选择你所要拍卖的月矿",
      },
      topDialogVisible: false,
      //月矿列表
      moonList: [],
      //输入框内容
      search: "",
      //当前页数
      currentPage: 1,
      // 总条数
      total: 0,

      // 显示一页多少条数据
      pagesize: 10,
      paiDialogVisible: false,
      // 拍卖表单对象
      paiForm: {
        moonname: "",
        moontype: "",
        gamename: "",
        money: "",
        time: "",
      },
      paiFormRules: {},
      paiFormRef: "",
      paimaibtn: '',
    };
  },
  methods: {
    // async gettime() {
    //   const { data: res } = await this.$http.get("/api/time");
    //   // console.log(res);
    //   this.nowtime = res.time;

    // },

    // async getmoontime() {
    //   const { data: res } = await this.$http.get("/api/moontime");
    //   // console.log(res);
    //   this.deadline = res[0].moon_time;
    //   this.deadline2 = Number(new Date(this.formatTime(this.deadline)))

    //   if (this.deadline2 > this.nowtime) {
    //     this.count = false

    //   } else {
    //     this.count = true

    //   }
    //   console.log(this.deadline);
    //   console.log(this.deadline2);
    //   console.log(this.count);

    // },
    //格式化时间
    formatTime(row, column) {
      let date = row;
      if (date === undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    cn() {
      this.$store.state.Cn = !this.$store.state.Cn;
      // console.log(this.$store.state.Cn);
    },
    async getlist() {
      const { data: res } = await this.$http.get("/api/moonpai");
      // console.log(res);
      this.moonList = res;
      this.total = res.length;
    },
    //切换月矿堡对话框
    switchmoon() {
      this.topDialogVisible = true;
      this.getlist();
    },

    //上面插值表达式
    handleswitch(index, row) {
      (this.moonpaiTable.moon_name = row.moon_name),
        (this.moonpaiTable.moon_type = row.moon_type),
        (this.$store.state.moonpai = row),
        // console.log(this.$store.state.moonpai);
        this.$bus.$emit("getmoonlist");
      this.topDialogVisible = false;
    },
    //拍卖moon按钮
    paimoon() {

      this.paiDialogVisible = true;
      this.paiForm.moonname = this.$store.state.moonpai.moon_name;
      this.paiForm.moontype = this.$store.state.moonpai.moon_type;
      this.paiForm.gamename = window.sessionStorage.gamename;
      this.getPaimaiBtn()

    },
    //拍卖对话框确定按钮
    paimai() {
      var time = new Date();
      var btime = time.toUTCString();

      this.paiForm.time = this.formatDateTime(btime);

      this.$http({
        method: "post",
        url: "/api/moonpai/add",
        data: this.paiForm,
      })
        .then((res) => {
          this.$bus.$emit("paimai");
          this.paiDialogVisible = false;
          console.log("1");
        })
        .catch((err) => {
          console.log("2");
        });

    },

    //监听 pagesize 改变的事件
    handleSizeChange(newsize) {
      //这里conso 选中第几页 最新的值
      console.log(newsize);
      //最新的条数（newsize）赋值给 动态的 pagesie
      this.pagesize = newsize;
      //获取到最新一页显示的数据  重新发送axios请求 这里是封装好的请求方法
      // this.userlistinfo();
    },

    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      console.log(newPage);
      //把最新的页码（newPage）赋值给 动态的 pagenum
      // this.pagenum = newPage;
      this.currentPage = newPage;
      //获取到最新显示的页码值  重新发送axios请求 这里是封装好的请求方法
      // this.userlistinfo();
    },
    //时间格式化
    formatDateTime(date) {
      if (date == "" || !date) {
        return "";
      }
      var date = new Date(date);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var second = date.getSeconds();
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    async getPaimaiBtn() {
      const { data: res } = await this.$http.get("/api/btn");
      this.paimaibtn = res[0].card;
      if (this.paimaibtn == '1') {
        this.count = false
        console.log(this.count);
      } else {
        this.count = true
        console.log(this.count);
      }
    },
  },
  mounted() { this.getPaimaiBtn() },
  created() {
    // this.getmoontime(),
    //   this.gettime(),
  },

  computed: {
    topList() {
      let list = this.moonList.filter(
        (item) =>
          item["moon_name"].toString().toLowerCase().indexOf(this.search) > -1
      );
      let table_data = list.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
      console.log(table_data);
      return table_data;
    },
  },
};
</script>

<style lang="less" scoped>
.top-card {
  width: 80%;
  height: 80px;
  position: absolute;
  left: 301px;
  top: 0;

  .cout {
    width: 100px;


    .cout-card {
      width: 400px;
      height: 80px;
      position: absolute;
      right: 450px;
      top: 0;
      text-align: center;
      align-content: center;
      background: linear-gradient(to bottom, rgba(250, 10, 10, 0.911), black);
      -webkit-text-fill-color: transparent;
      -webkit-background-clip: text;
      display: block;

    }
  }

  .btn {
    position: absolute;
    right: 50px;
    top: 20px;
  }

  //显示是否拍卖的指示器
  .bookcard {
    width: 400px;
    height: 80px;
    position: absolute;
    left: 150px;
    top: 0;
    text-align: center;
    align-content: center;
    font-size: 40px;
    background: linear-gradient(to bottom, rgba(250, 10, 10, 0.911), black);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    display: block;
  }
}
</style>