<template>
  <div class="bgc">
    <el-button @click="iscn" class="cn">简体中文/English</el-button>
    <div class="zhuce">
      <el-form
        ref="zhuceFormRef"
        :model="zhuceForm"
        label-width="200px"
        :rules="zhuceRules"
      >
        <el-form-item prop="username" v-if="Cn">
          <span slot="label" class="zi">用户名</span>
          <el-input v-model="zhuceForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="username" v-else="!Cn">
          <span slot="label" class="zi">username</span>
          <el-input v-model="zhuceForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="password" v-if="Cn">
          <span slot="label" class="zi">密码</span>
          <el-input v-model="zhuceForm.password"></el-input>
        </el-form-item>
        <el-form-item prop="password" v-else="!Cn">
          <span slot="label" class="zi">password</span>
          <el-input v-model="zhuceForm.password"></el-input>
        </el-form-item>
        <el-form-item label="游戏内角色名" prop="gamename" v-if="Cn">
          <span slot="label" class="zi">游戏内角色名</span>
          <el-input v-model="zhuceForm.gamename"></el-input>
        </el-form-item>
        <el-form-item label="游戏内角色名" prop="gamename" v-else="!Cn">
          <span slot="label" class="zi"> Ingame Character Name</span>
          <el-input v-model="zhuceForm.gamename"></el-input>
        </el-form-item>
        <el-form-item label="游戏内公司名" prop="company" v-if="Cn">
          <span slot="label" class="zi">游戏内公司名</span>
          <el-input v-model="zhuceForm.company"></el-input>
        </el-form-item>
        <el-form-item label="游戏内公司名" prop="company" v-else="!Cn">
          <span slot="label" class="zi">Company name in game</span>
          <el-input v-model="zhuceForm.company"></el-input>
        </el-form-item>
        <el-form-item v-if="Cn">
          <el-button @click="zhuce">注册</el-button>
          <el-button @click="chongzhi" class="fr">重置</el-button>
        </el-form-item>
        <el-form-item v-else="!Cn">
          <el-button @click="zhuce">Registered account</el-button>
          <el-button @click="chongzhi" class="fr">Resetting</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      title="注册失败"
      :visible.sync="zhuceDialogVisible"
      width="30%"
      v-if="Cn"
    >
      <span>{{ this.zhuceErr }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="zhuceDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="zhuceDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      title="注册失败"
      :visible.sync="zhuceDialogVisible"
      width="30%"
      v-else="!Cn"
    >
      <span>{{ this.zhuceErr }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="zhuceDialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="zhuceDialogVisible = false"
          >Define</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      Cn: true,
      zhuceForm: {
        username: "",
        password: "",
        gamename: "",
        company: "",
      },
      zhuceFormRef: "",
      zhuceErr: "",
      zhuceDialogVisible: false,
      gamename: [],
      zhuceRules: {
        username: [
          {
            required: true,
            message: "请输入用户名/Please into your user name",
            trigger: "blur",
          },
          {
            min: 3,
            max: 15,
            message:
              "用户名长度在3到15位/The user name is between 3 and 15 digits in length",
            trigger: "blur",
          },
          {
            validator: function (rule, value, callback) {
              //  校验英文的正则
              if (/[a-zA-z]$/.test(value) == false) {
                callback(new Error("请输入英文"));
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码/Please into your password",
            trigger: "blur",
          },
          {
            min: 3,
            max: 15,
            message:
              "密码长度在3到15位/Password length is between 3 and 15 digits",
            trigger: "blur",
          },
          {
            validator: function (rule, value, callback) {
              //  校验英文的正则
              if (/[a-zA-z][0-9]*$/.test(value) == false) {
                callback(
                  new Error(
                    "请输入英文和数字组成的密码/Please enter a password in English and numbers"
                  )
                );
              } else {
                //校验通过
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        gamename: [
          {
            required: true,
            message:
              "请输入eve内角色名/Please enter the role name in EVE Online",
            trigger: "blur",
          },
        ],
        company: [
          {
            required: true,
            message:
              "请输入eve内公司角色名(简写即可)/Please enter the company role name in EVE Online (abbreviated)",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    iscn() {
      this.Cn = !this.Cn;
      console.log(this.Cn);
    },
    chongzhi() {
      this.zhuceForm.username = "";
      this.zhuceForm.password = "";
      this.zhuceForm.gamename = "";
      this.zhuceForm.company = "";
    },
    zhuce() {
      this.$refs.zhuceFormRef.validate(async (validate) => {
        window.sessionStorage.gamename = this.zhuceForm.gamename;
        if (validate) {
          const { data: res } = await this.$http.post(
            "/api/zhuce?username=" +
              this.zhuceForm.username +
              "&password=" +
              this.zhuceForm.password +
              "&gamename=" +
              this.zhuceForm.gamename +
              "&company=" +
              this.zhuceForm.company
          );
          if (res.zhuceCode == 1) {
            this.$router.push("/usermoon");
          } else {
            this.zhuceErr = res.zhuceMessage;
            this.zhuceDialogVisible = true;
          }
        } else {
          alert("请填写所有数据/Please fill in all data");
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgc {
  height: 100%;
  background-image: url(../assets/image/bg.gif) !important;
  .cn {
    position: absolute;
    left: 90%;
    top: 0;
  }
  .zhuce {
    width: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    .zi {
      color: white;
    }
  }
}
</style>


