<template>
  <div class="bgc">
    <el-col :span="5"
      ><div><left-menu></left-menu></div
    ></el-col>
    <el-col :span="19"
      ><div>
        <list-top></list-top>
        <router-view></router-view></div
    ></el-col>
  </div>
</template>

<script>
import leftMenu from "../components/leftMenu.vue";
import listTop from "../components/listTop.vue";
export default {
  components: { leftMenu, listTop },
  data() {
    return {};
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
  },
};
</script>

<style lang="less" scoped>
.bgc {
  width: 100%;
  height: 100%;
}
</style>