import Vue from 'vue'
import VueRouter from 'vue-router'
import Delu from '@/views/Delu.vue'
import Paimai from '@/views/Paimai.vue'
import Paimaiadmin from '@/views/Paimaiadmin.vue'
import Zhuce from '@/views/Zhuce.vue'
import listMoon from '@/components/listMoon.vue'
import listPaiMoon from '@/components/listPaiMoon.vue'
import addMoon from '@/components/addMoon.vue'
import userMoon from '@/components/userMoon.vue'
import userPaiMoon from '@/components/userPaiMoon.vue'
import button  from'@/components/Button.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Delu
  },
  {
    path: '/zhuce',
    name: 'zhuce',
    component: Zhuce
  },
  {
    path: '/paimai',
    name: 'paimai',
    component: Paimai,
    children: [{
      path: '/usermoon',
      name: 'usermoon',
      component: userMoon
    }, {
      path: '/userpaimoon',
      name: 'userpaimoon',
      component: userPaiMoon
    }]
  },
  {
    path: '/paimaiadmin',
    name: 'paimaiadmin',
    component: Paimaiadmin,
    children: [{
      path: '/listmoon',
      name: 'listMoon',
      component: listMoon
    }, {
      path: '/paimoon',
      name: 'listPaiMoon',
      component: listPaiMoon
    },
    {
      path: '/addmoon',
      name: 'addMoon',
      component: addMoon
    },
    {
      path: '/btn',
      name: 'button',
      component: button
    },
    ]
  },


]

const router = new VueRouter({
  routes
})

export default router
