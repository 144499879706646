<template>
  <div class="list">
    <el-card class="pai-card" v-if="moonTable.length != 0">
      <el-table :data="uvList" style="width: 100%" :default-sort="{ prop: 'time', order: 'descending' }" height="700px">
        <el-table-column label="序号" width="70" v-if="this.$store.state.Cn">
          <template slot-scope="scope">
            <span> {{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Number" width="70" v-else="!this.$store.state.Cn">
          <template slot-scope="scope">
            <span> {{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="moon_name" label="月矿堡名称" v-if="this.$store.state.Cn">
        </el-table-column>
        <el-table-column prop="moon_name" label="Name of Moon Mining Castle" v-else="!this.$store.state.Cn">
        </el-table-column>
        <el-table-column label="月矿类型" prop="moon_type" v-if="this.$store.state.Cn" width="180">
        </el-table-column>
        <el-table-column label="Mineral type" prop="moon_type" v-else="!this.$store.state.Cn" width="180">
        </el-table-column>
        <el-table-column prop="user_gamename" label="游戏姓名" width="180" v-if="this.$store.state.Cn">
        </el-table-column>
        <el-table-column prop="user_gamename" label="Gamename" width="180" v-else="!this.$store.state.Cn">
        </el-table-column>
        <el-table-column prop="money" label="价格(Misk)" :sortable=true width="180" v-if="this.$store.state.Cn">
        </el-table-column>
        <el-table-column prop="money" label="Money(Misk)" :sortable=true width="180" v-else="!this.$store.state.Cn">
        </el-table-column>
        <el-table-column prop="time" label="时间" :sortable=true width="180" :formatter="formatTime"
          v-if="this.$store.state.Cn">
        </el-table-column>
        <el-table-column prop="time" label="Time" :sortable=true width="180" :formatter="formatTime"
          v-else="!this.$store.state.Cn">
        </el-table-column>
        <el-table-column v-if="this.$store.state.Cn">
          <template slot="header" slot-scope="scope">
            <el-button @click="getpai">刷新</el-button>
          </template>
        </el-table-column>
        <el-table-column v-else="!this.$store.state.Cn">
          <template slot="header" slot-scope="scope">
            <el-button @click="getpai">Refresh </el-button>
          </template>
        </el-table-column>
      </el-table>


      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[5, 10]" layout="total, sizes, prev, pager, next ,jumper" :total="total">
      </el-pagination>
    </el-card>
    <span v-else="moonTable.length == 0" class="zi">请点击您要想进行拍卖的月矿/Please select you want Moon Mining Castle</span>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "listMoon",

  data() {
    return {
      //表格
      moonTable: [],
      //请求体
      moonpaiTable: {},
      //当前页数
      currentPage: 1,
      // 总条数
      total: 0,

      // 显示一页多少条数据
      pagesize: 10,
    };
  },
  methods: {

    async getpai() {
      this.moonpaiTable = this.$store.state.moonpai;
      console.log(this.moonpaiTable);
      const { data: res } = await this.$http.get(
        "/api/moonpai/shuaxi?moon_name=" +
        this.moonpaiTable.moon_name +
        "&moon_type=" +
        this.moonpaiTable.moon_type
      );

      this.moonTable = res;
      this.total = res.length
    },
    //格式化时间
    formatTime(row, column) {
      let date = row[column.property];
      if (date === undefined) {
        return "";
      }
      return moment(date).format("YYYY-MM-DD HH:mm:ss");
    },
    //监听 pagesize 改变的事件
    handleSizeChange(newsize) {
      //这里conso 选中第几页 最新的值
      console.log(newsize);
      //最新的条数（newsize）赋值给 动态的 pagesie
      this.pagesize = newsize;
      //获取到最新一页显示的数据  重新发送axios请求 这里是封装好的请求方法
      // this.userlistinfo();
      this.getpai()
    },

    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      console.log(newPage);
      //把最新的页码（newPage）赋值给 动态的 pagenum
      // this.pagenum = newPage;
      this.currentPage = newPage;
      //获取到最新显示的页码值  重新发送axios请求 这里是封装好的请求方法
      // this.userlistinfo();
      this.getpai()
    },
  },
  mounted() {
    this.getpai();
    this.$bus.$on("getmoonlist", () => {
      this.getpai();
    });
    this.$bus.$on("paimai", () => {
      this.getpai();
    });
  },
  beforeDestroy() {
    this.$bus.$off('getmoonlist'); //$off解绑当前组件所用到的事件
    this.$bus.$off('paimai')
  },
  computed: {
    uvList() {
      // if (typeof this.allUvList === "undefined") {
      //   console.log(this);
      //   return;
      // } else {
      // let list = this.allUvlist.filter(
      //   (item) =>
      //     item["moon_name"].toString().toLowerCase().indexOf(this.search) > -1
      // );
      let table_data = this.moonTable.slice(
        (this.currentPage - 1) * this.pagesize,
        this.currentPage * this.pagesize
      );
      return table_data;
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  .pai-card {
    width: 80%;
    height: 800px;
    position: absolute;
    left: 301px;
    top: 80px;
  }

  .zi {
    width: 80%;

    position: absolute;
    left: 301px;
    top: 80px;
    height: 80px;
    font-size: 50px;
    text-align: center;
    align-content: center;
    background: linear-gradient(to bottom, white, black);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    display: block;
  }
}
</style>