import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: '',
    gamename: '',
    Cn: true,
    moonpai: {
      moon_name: "请选择你所要拍卖的月矿",
      moon_type: "请选择你所要拍卖的月矿",
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
