<template>
  <div class="bgc">
    <el-col :span="2"></el-col>
    <div class="leftmenu">
      <el-menu default-active="1" class="el-menu-vertical-demo" background-color="#545c64" text-color="#fff"
        active-text-color="#ffd04b">
        <el-menu-item index="1" @click="getpailist" v-if="this.$store.state.Cn">
          <template slot="title">
            <span>拍卖月矿</span>
          </template>

          <!-- <el-menu-item-group>
          <template slot="title">分组一</template>
          <el-menu-item index="1-1">选项1</el-menu-item>
          <el-menu-item index="1-2">选项2</el-menu-item>
        </el-menu-item-group>
        <el-menu-item-group title="分组2">
          <el-menu-item index="1-3">选项3</el-menu-item>
        </el-menu-item-group>
        <el-submenu index="1-4">
          <template slot="title">选项4</template>
          <el-menu-item index="1-4-1">选项1</el-menu-item>
        </el-submenu> -->
        </el-menu-item>
        <el-menu-item index="1" @click="getpailist" v-else="!this.$store.state.Cn">
          <template slot="title">
            <span>Auction of Moon Mining Castle</span>
          </template>
        </el-menu-item>
        <el-menu-item index="2" @click="getaddlist" v-if="this.$store.state.Cn">
          <span slot="title">添加拍卖月矿星系</span>
        </el-menu-item>
        <el-menu-item index="2" @click="getaddlist" v-else="!this.$store.state.Cn">
          <span slot="title">Add Auction Moon Mining Castle</span>
        </el-menu-item>
        <el-menu-item index="3" @click="getmoonList" v-if="this.$store.state.Cn">
          <span slot="title">月矿堡列表</span>
        </el-menu-item>
        <el-menu-item index="3" @click="getmoonList" v-else="!this.$store.state.Cn">
          <span slot="title">Moon Mining Castle List</span>
        </el-menu-item>
        <el-menu-item index="4" @click="paimaibtn" v-if="this.$store.state.Cn">
          <span slot="title">打开拍卖或者关闭拍卖</span>
        </el-menu-item>
        <el-menu-item index="4" @click="paimaibtn" v-else="!this.$store.state.Cn">
          <span slot="title">Open or close an auction</span>
        </el-menu-item>
        <!-- <el-menu-item index="4">
        <i class="el-icon-setting"></i>
        <span slot="title">导航四</span>
      </el-menu-item> -->
      </el-menu>
      <!-- 英文 -->
    </div>
    </el-col>
    <!-- 添加拍卖月矿星系对话框 -->
  </div>
</template>

<script>
export default {
  name: "leftMenuadmin",
  data() {
    return {
      Cn: this.$store.state.Cn,
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },

    //跳转所有月矿堡列表
    getmoonList() {
      this.$router.push("/listmoon").catch((err) => { });
    },
    //跳转所有拍卖列表
    getpailist() {
      this.$router.push("/paimoon").catch((err) => { });
    },
    getaddlist() {
      this.$router.push("/addmoon").catch((err) => { });
    },
    paimaibtn() {
      this.$router.push("/btn").catch((err) => { });
    },
  },
};
</script>

<style lang="less" scoped>
.bgc {
  width: 100%;
  height: 100%;

  .el-menu {
    height: 100vh;
    width: 300px;
  }
}
</style>