<template>
  <div class="addform">
    <el-card class="box-card">
      <el-form
        ref="addTableRef"
        :model="addTable"
        label-width="250px"
        :rules="addTableRules"
      >
        <el-form-item
          label="月矿堡名称"
          prop="addname"
          v-if="this.$store.state.Cn"
        >
          <el-input v-model="addTable.addname"></el-input>
        </el-form-item>
        <el-form-item
          label="Name of Moon Mining Castle"
          prop="addname"
          v-else="!this.$store.state.Cn"
        >
          <el-input v-model="addTable.addname"></el-input>
        </el-form-item>
        <el-form-item
          label="矿物类型"
          prop="addtype"
          v-if="this.$store.state.Cn"
        >
          <el-select
            v-model="addTable.addtype"
            placeholder="请选择月矿矿物类型"
          >
            <el-option label="64" value="64"></el-option>
            <el-option label="32" value="32"></el-option>
            <el-option label="16" value="8"></el-option>
            <el-option label="8" value="8"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="Mineral type"
          prop="addtype"
          v-else="!this.$store.state.Cn"
        >
          <el-select
            v-model="addTable.addtype"
            placeholder="Please select the type of moon mineral"
          >
            <el-option label="64" value="64"></el-option>
            <el-option label="32" value="32"></el-option>
            <el-option label="16" value="8"></el-option>
            <el-option label="8" value="8"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="租聘者"
          prop="addhirer"
          v-if="this.$store.state.Cn"
        >
          <el-input
            v-model="addTable.addhirer"
            placeholder="请输入租聘者(可以不填，默认admin)"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Hirer of Moon Mining Castle"
          prop="addhirer"
          v-else="!this.$store.state.Cn"
        >
          <el-input
            v-model="addTable.addhirer"
            placeholder="Please enter the renter (optional, default admin)"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="this.$store.state.Cn">
          <el-button @click="chongzhi">重置</el-button>
          <el-button type="primary" @click="addmoon">确 定</el-button>
        </el-form-item>
        <el-form-item v-else="!this.$store.state.Cn">
          <el-button @click="chongzhi">Resetting</el-button>
          <el-button type="primary" @click="addmoon">Define</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addTable: {
        addname: "",
        addtype: "",
        addhirer: "admin",
      },
      addTableRules: {
        addname: [
          {
            required: true,
            message:
              "请输入月矿堡名称/Please enter the name of Moon Mining Castle",
            trigger: "blur",
          },
        ],
        addtype: [
          {
            required: true,
            message: "请选择月矿类型/Please select the type of monthly mine",
            trigger: "blur",
          },
        ],
      },
      addTableRef: "",
    };
  },
  methods: {
    addmoon() {
      this.$refs.addTableRef.validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http.post(
            "/api/moon/add?moonname=" +
              this.addTable.addname +
              "&moontype=" +
              this.addTable.addtype +
              "&moonhirer=" +
              this.addTable.addhirer
          );
          console.log(res);
          alert(res.moonMessage);
        } else {
          alert(
            "请按照标准填写月矿堡数据/Please fill in the monthly mining fort data according to the standard"
          );
          return false;
        }
      });
    },
    chongzhi() {
      (this.addTable.addname = ""),
        (this.addTable.addtype = ""),
        (this.addTable.addhirer = "");
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.addform {
  .box-card {
    width: 80%;
    height: 800px;
    position: absolute;
    left: 301px;
    top: 80px;
    .el-form {
      position: absolute;
      left: 0%;
      top: 5%;

      .el-input {
        width: 300px;
      }
    }
  }
}
</style>