<template>
    <div class="list">

        <el-card class="pai_card" v-if="this.$store.state.Cn">
            <el-button @click="start">拍卖开启</el-button>
            <el-button @click="end">拍卖关闭</el-button>
        </el-card>
        <el-card class="pai_card" v-else="!this.$store.state.Cn">
            <el-button @click="start">Open this auction</el-button>
            <el-button @click="end">Close this auction</el-button>
        </el-card>
    </div>
</template>

<script>

export default {

    data() {
        return {
            btn: '0',
        }
    },
    methods: {
        async start() {
            this.btn = '1'
            const { data: res } = await this.$http.post("/api/paibtn?card=" + this.btn)
            alert(res.Message);
            location.reload();
        },
        async end() {
            this.btn = '0'
            const { data: res } = await this.$http.post("/api/paibtn?card=" + this.btn)
            alert(res.Message);
            location.reload();
        }

    },

}
</script>

<style lang="less" scoped>
.list {
    position: relative;
    width: 600px;
    top: 69px;
    left: -40PX;

    .pai-card {
        width: 600px;
        height: 800px;
        position: absolute;
        left: 49px;
        top: 20px;

    }
}
</style>